/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Housekeep design tokens */
@import '@housekeep/design-tokens/dist/tokens';
@import './theme/design-tokens';

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* Housekeep CSS */
@import './theme/colors';
@import './theme/mixins/spacing';

@import './theme/action-sheets';
@import './theme/alerts';
@import './theme/alignment';
@import './theme/animations';
@import './theme/background';
@import './theme/badges';
@import './theme/borders';
@import './theme/buttons';
@import './theme/cards';
@import './theme/chat';
@import './theme/content';
@import './theme/fonts';
@import './theme/forms';
@import './theme/grid';
@import './theme/help';
@import './theme/icons';
@import './theme/items';
@import './theme/lists';
@import './theme/loading';
@import './theme/margins';
@import './theme/modals';
@import './theme/platforms';
@import './theme/segments';
@import './theme/slides';
@import './theme/spinners';
@import './theme/swipers';
@import './theme/text';
@import './theme/toasts';
@import './theme/toolbars';

.inline-block {
  display: inline-block !important;
}

.w-48 {
  width: 48px !important;
}

// Hide the Zendesk Web Widget launcher when the widget is not open
#launcher:not([style*='launcherOnOpen']) {
  display: none;
}

// Resize the Zendesk Web Widget to avoid overlapping the status bar and home button
@media (max-width: $breakpoint-md) {
  iframe[name='Messaging window'] {
    bottom: var(--ion-safe-area-bottom, 0) !important;
    top: var(--ion-safe-area-top, 0) !important;
    height: calc(100% - var(--ion-safe-area-top, 0) - var(--ion-safe-area-bottom, 0)) !important;
  }

  div:has(iframe[name='Messaging window'][tabindex='0']) {
    background-color: $color-brand-blue-500;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}
