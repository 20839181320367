form {
  .label {
    &--required::after {
      content: ' *';
    }
  }
}

ion-label {
  white-space: normal !important; // Prevents truncation of input labels on iOS
}

ion-checkbox[slot='start'] {
  --border-radius: #{$border-radius-form-control-medium};
  --size: 1.25rem;

  &.md {
    margin-right: 16px;
  }

  &.ios {
    margin-right: 12px;
  }
}

ion-radio {
  margin-right: 0.5rem !important;

  &.ios {
    height: 20px;
    width: 20px;

    &::part(container) {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.23);
    }

    &.radio-checked {
      &::part(container) {
        border-color: $color-primary;
      }
      &::part(mark) {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        border: none;
        background-color: $color-primary;
      }
    }
  }
}

ion-input,
ion-textarea,
ion-select,
ion-datetime {
  &.ios,
  &.md {
    --color: #{$color-form-control-default};
    --placeholder-color: #{$color-form-control-placeholder};
    --placeholder-opacity: 1;
    font-weight: 300;
  }
}

ion-datetime {
  --background: var(--ion-color-white);
  --background-rgb: var(--ion-color-white-rgb);
  width: 300px !important;

  @media (min-width: 360px) {
    width: 320px !important;
  }
}

ion-popover {
  --background: var(--ion-color-white);
  --backdrop-opacity: 0.3;
  --height: fit-content;

  &::part(content) {
    left: 50% !important;
    transform-origin: unset !important;
    transform: translate(-50%, 0%) !important;
  }

  &.center {
    &::part(content) {
      left: 50% !important;
      top: calc(50% - 160px) !important;
      transform-origin: unset !important;
    }

    &::part(arrow) {
      display: none;
    }
  }
}

ion-datetime-button {
  width: 100%;

  &::part(native) {
    padding: 8px 12px;
    margin: 0;
    line-height: 1.35;
    font-weight: 300;
    background-color: $color-white;
    border: 1px solid $border-color-form-control-default;
    border-radius: $border-radius-form-control-medium;
    text-align: left;
    width: 100%;
  }

  [slot='date-target'].text--muted {
    color: #b4c2c9 !important;
  }
}

.validation-errors {
  display: block;
  margin: 5px 0 10px;

  p {
    font-size: 0.9rem;
    margin: 0 0 2px;
  }
}

.range {
  width: 100%;

  ion-range {
    padding-inline: 0;
  }
}
