@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(2rem);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes spin-pause {
  0%,
  20% {
    transform: rotate(0deg);
  }
  80%,
  100% {
    transform: rotate(359deg);
  }
}

@keyframes fade-in-out-pause {
  0%,
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  60%,
  100% {
    opacity: 1;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-1px);
  }
  20%,
  80% {
    transform: translateX(2px);
  }
  30%,
  50%,
  70% {
    transform: translateX(-4px);
  }
  40%,
  60% {
    transform: translateX(4px);
  }
}

.animate {
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;

  &--fade-in {
    animation-name: fade-in;
  }

  &--slide-up {
    animation-name: slide-up;
  }

  &--fade-slide {
    animation-name: fade-in, slide-up;
  }

  &--spin-pause {
    animation-name: spin-pause;
    animation-iteration-count: infinite;
    animation-duration: 3s;
  }

  &--fade-in-out-pause {
    animation-name: fade-in-out-pause;
    animation-iteration-count: infinite;
    animation-duration: 6s;
  }

  &--shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }
}
