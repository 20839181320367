ion-list {
  &.ios {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.list--unstyled {
  margin: 0;
  padding: 0;
  padding-inline-start: 0 !important;
  list-style-type: none;
}

.list--no-inset {
  ion-item {
    --padding-start: 0;
  }
}
