ion-action-sheet {
  &.ios {
    --background: var(--ion-color-step-50);
    --background-activated: var(--ion-background-color);
    --background-selected: var(--ion-background-color);
  }
}

.ios {
  .action-sheet-button:not(.action-sheet-cancel) {
    font-weight: $font-weight-medium;
    font-size: 1.15rem;
  }

  .action-sheet-cancel {
    font-weight: $font-weight-medium;
  }
}
