@import '@housekeep/design-tokens/dist/tokens';

ion-button {
  letter-spacing: unset;
  margin-inline: 0;
}

.selectable-button {
  --border-color: var(--ion-color-step-100);
  --background: var(--ion-color-step-50);
  --background-hover: #fff;
  --background-focus: #fff;
  --background-activated: var(--ion-color-primary);
  --background-activated-opacity: 1;
  --color: #000;
  --color-hover: var(--ion-color-primary);
  --color-focus: var(--ion-color-primary);
  --color-activated: var(--ion-color-primary-contrast);

  // Selected.
  &.is-selected {
    --border-color: var(--ion-color-primary);
    --background: #fff;
    --background-hover: var(--ion-color-step-50);
    --background-focus: var(--ion-color-step-50);
    --color: var(--ion-color-primary);
  }

  // Disabled.
  &.is-disabled,
  &.is-disabled-danger,
  &[disabled] {
    --opacity: #{$opacity-button-disabled};
  }

  // Disabled, not selected.
  &:not(.is-selected).is-disabled,
  &:not(.is-selected)[disabled] {
    --border-color: var(--ion-color-step-50);
    --color: var(--ion-color-step-500);
  }

  // Selected and disabled, red highlighted.
  &.is-selected.is-disabled-danger,
  &.is-selected.is-disabled-danger[disabled] {
    --border-color: var(--ion-color-danger);
    --color: var(--ion-color-danger);
  }
}

// Styling required for non-Ionic buttons within hk-chat-client
button[disabled] {
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
}
