.counter {
  counter-reset: instruction-steps;

  &--step {
    counter-increment: instruction-steps;
    margin-inline: 0;
  }

  &--title {
    .counter--step &:before {
      content: counter(instruction-steps) '. ';
    }
  }
}
