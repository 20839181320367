html {
  font-size: 15px;
  font-weight: $font-weight-body;
}

a {
  text-decoration: none;

  &:not(href) {
    cursor: pointer;
  }
}

b,
strong,
.strong {
  font-weight: $font-weight-medium !important;
}

small,
.small {
  font-size: 0.9rem !important;
}

.text-normal {
  font-weight: $font-weight-body !important;
}

// Prevents truncation of titles on iOS
ion-title.title-ios {
  padding-left: 4px;
  padding-right: 4px;
}

ion-title {
  font-weight: $font-weight-medium;

  &.md {
    padding-inline-start: 10px;
  }
}

// Remove uppercase text transform on iOS list headers
ion-list-header.ios {
  text-transform: none;
  letter-spacing: normal;
  font-size: 0.9rem;
}

ion-note {
  --color: var(--ion-color-step-500);
}

help-request-ticket-comment .comment-body blockquote {
  margin: 10px 0;
}

.h2,
.h3,
.h4 {
  display: flex;
  align-items: center;
  font-weight: $font-weight-medium;
  margin-bottom: 0.5rem;
}

.text {
  &--muted {
    color: $color-grey-600 !important;
  }

  &--primary {
    color: $color-primary !important;
  }

  &--warning {
    color: $color-orange-500 !important;
  }

  &--danger {
    color: $color-red-500 !important;
  }

  &--success {
    color: $color-green-500 !important;
  }

  &--white {
    color: $color-white !important;
  }

  &--pre-wrap {
    white-space: pre-wrap;
  }

  &--no-wrap {
    white-space: nowrap;
  }

  &--light {
    font-weight: $font-weight-light !important;
  }

  &--normal {
    font-weight: $font-weight-normal !important;
  }

  &--bold {
    font-weight: $font-weight-medium !important;
  }

  &--capitalize {
    text-transform: capitalize;
  }
}

// TODO cut
.content__no-content-message {
  text-align: center;
  color: $color-grey-600;
}

$headingSizeMap: (
  'h1': 2.5rem,
  'h2': 2rem,
  'h3': 1.75rem,
  'h4': 1.5rem,
  'h5': 1.25rem,
  'h6': 1rem,
  'li': 1rem,
  'p': 1rem,
  'text-large': 1.25rem,
  'text-medium': 1rem,
  'text-small': 0.9rem
);

@each $name, $value in $headingSizeMap {
  #{$name},
  .#{$name} {
    font-size: $value;
  }
}

ul li,
ol li {
  margin-bottom: 0.5rem;
}

ul li:last-child,
ol li:last-child {
  margin-bottom: 0px;
}
