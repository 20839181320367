.chat-title {
  &.ios {
    text-align: left;
    padding-inline-start: 40px;
    height: 55px;
    margin-top: -0.375rem;
  }

  &.typing-indicator {
    padding-top: 0;
    margin-top: -0.25rem;

    span {
      display: block;
      font-size: 0.75rem;
      margin-top: -0.25rem;
      font-weight: $font-weight-light;
    }
  }
}

.comms {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__chat-interface {
    flex-grow: 1;
    flex-basis: 1px;
    padding-bottom: var(--ion-safe-area-bottom, 0);
  }
}
