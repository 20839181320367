.grid {
  &--negative-margin {
    // Ionic styles add 5px padding to the `ion-grid` component and
    // 5px padding to `ion-col`. This class applies negative left and right
    // margins to better align the grid contents with surrounding contents.
    margin-left: -10px;
    margin-right: -10px;
    width: auto;
  }
}
