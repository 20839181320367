.hide {
  display: none;
}

.md .hide-md {
  display: none;
}

.ios .hide-ios {
  display: none;
}

.show {
  display: unset;
}

.md .show-md {
  display: unset;
}

.ios .show-ios {
  display: unset;
}
