ion-toast {
  &.toast {
    /**
     * Toast Themes (see ToastService)
     */
    &--danger {
      --background: var(--ion-color-danger);
      --button-color: var(--ion-color-danger-contrast);
      --color: var(--ion-color-danger-contrast);
    }

    &--info {
      --background: var(--ion-color-secondary-shade);
      --button-color: #ffffff;
      --color: #ffffff;
    }

    &--success {
      --background: var(--ion-color-success-shade);
      --button-color: var(--ion-color-success-contrast);
      --color: var(--ion-color-success-contrast);
    }

    &--success-tint {
      --background: var(--ion-color-success-tint);
      --button-color: var(--ion-color-success-contrast);
      --color: var(--ion-color-success-contrast);
    }

    &--warning {
      --background: var(--ion-color-warning-shade);
      --button-color: var(--ion-color-warning-contrast);
      --color: var(--ion-color-warning-contrast);
    }

    &--demo-mode {
      --background: #{$color-red-700};
      --button-color: #ffffff;
      --color: #ffffff;
    }

    /*
     * Persistent toasts
     */
    &--persistent {
      // Ionic starts the z-indices of toasts at 60000 and increases by 1 for every toast.
      // created. We want the persistent message to always be the top one.
      z-index: 99999 !important;

      --width: 100%;
      --start: 0;
      --end: 0;
      --border-radius: 0;
    }
  }
}

// Add some padding to the bottom of the page and menus if a persistent toast is shown
body.has-persistent-toast {
  .ion-page,
  .menu-primary__list,
  schedule-list > ion-list:last-child {
    padding-bottom: 48px;
  }

  .toast--persistent.ios {
    --ion-safe-area-bottom: 8px;
  }

  .toast--non-persistent {
    --ion-safe-area-bottom: 48px;

    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
      --ion-safe-area-bottom: calc(48px + constant(safe-area-inset-bottom));
    }

    @supports (padding-top: env(safe-area-inset-bottom)) {
      --ion-safe-area-bottom: calc(48px + env(safe-area-inset-bottom));
    }
  }
}

$toast-transition-duration: 0.4s;
$toast-transition-timing-function: cubic-bezier(0.36, 0.66, 0.04, 1);
$persistent-toast-height: 4.4rem;

.toast {
  &--danger {
    .toast-container {
      background-color: $color-danger;
    }
  }

  &--info {
    .toast-container {
      background-color: $color-brand-bright-green-600;
    }
  }

  &--success {
    .toast-container {
      background-color: $color-success;
    }
  }

  &--warning {
    .toast-container {
      background-color: $color-warning;
    }
  }

  &--persistent {
    // Ionic starts the z-indices of toasts at 19999 and increases by 1 for every toast.
    // crated. We want the persistent message to always be the top one.
    z-index: 50000 !important;

    &::part(message) {
      text-align: center;
      font-weight: 700;
    }

    &::part(button) {
      margin: 0;
    }
  }

  &--demo-mode {
    .toast-container {
      background-color: $color-red-700;
    }
  }
}

.ios {
  .toast {
    &--persistent {
      &.toast--danger {
        .toast-wrapper {
          background-color: darken($color-danger, 20%);
        }
      }

      &.toast--info {
        .toast-wrapper {
          background-color: darken($color-brand-bright-green-500, 20%);
        }
      }

      &.toast--success {
        .toast-wrapper {
          background-color: darken($color-success, 20%);
        }
      }

      &.toast--warning {
        .toast-wrapper {
          background-color: darken($color-warning, 20%);
        }
      }
    }

    &--demo-mode {
      .toast-wrapper {
        background-color: darken($color-red-700, 20%) !important;
      }
    }
  }
}

ion-content[padding] > .scroll-content {
  transition: margin-bottom $toast-transition-duration;
  transition-timing-function: $toast-transition-timing-function;
}

ion-footer {
  transition: bottom $toast-transition-duration;
  transition-timing-function: $toast-transition-timing-function;
}
