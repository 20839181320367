@import './breakpoints';

ion-item {
  --detail-icon-color: var(--ion-color-step-500);
  --detail-icon-opacity: 0.5;
  --border-width: 0;
  --full-highlight-height: 0;

  &.item-radio-checked,
  &.item-checkbox-checked {
    --color: var(--ion-color-primary);
  }

  ion-checkbox + ion-label,
  ion-radio + ion-label {
    font-size: 1rem !important;
  }

  &.item--input-border {
    --border-color: transparent !important;
    --highlight-height: 0px;
    --padding-start: 2px;
    --inner-padding-end: 2px;
    font-size: 1rem;

    &.md {
      margin-bottom: 10px;
    }

    &.ios {
      margin-bottom: 15px;
    }

    .label-stacked {
      font-size: 1rem;
      margin-top: 0;
      margin-bottom: 8px;

      &.md {
        transform: none !important;
        margin-bottom: 5px;
      }
    }

    ion-input,
    ion-select,
    ion-textarea,
    ion-datetime {
      --padding-start: #{$padding-form-control-medium} !important;
      --padding-end: #{$padding-form-control-medium} !important;
      border: 1px solid $border-color-form-control-default;
      border-radius: $border-radius-form-control-medium;
    }

    ion-select {
      width: 100%;
      max-width: none !important;
    }

    ion-radio-group {
      width: 100%;
    }

    ion-textarea {
      margin-top: 0 !important;
    }

    ion-note {
      margin-top: 5px;
      font-weight: $font-weight-body;
      font-size: 0.9rem !important;
    }

    ion-label + ion-note {
      margin-top: -5px;
      margin-bottom: 10px;
    }

    &.item-has-focus,
    &.activated {
      ion-input,
      ion-textarea {
        --color: #{$color-form-control-focus};
        border-color: $border-color-form-control-focus;
      }
    }

    ion-select[aria-expanded='true'],
    ion-datetime[aria-expanded='true'] {
      border-color: $border-color-form-control-focus;
      color: $color-form-control-focus;
    }
  }

  > ion-label > p {
    color: var(--ion-text-color) !important;
    font-weight: $font-weight-body;
  }
}

.item {
  font-weight: $font-weight-medium;

  &--no-padding {
    --padding-end: 0px !important;
    --padding-start: 0px !important;
    --inner-padding-end: 0px !important;
    --inner-padding-start: 0px !important;
  }

  &--no-top-border {
    border-top-width: 0 !important;
  }

  &--no-bottom-border {
    .item-inner {
      border-bottom-width: 0 !important;
    }
  }

  &--input-border {
    .item-inner {
      padding-right: 0 !important;
      border: 0 none !important;
      box-shadow: none !important;
    }

    ion-label[stacked] {
      font-size: 1.4rem;
    }

    ion-input,
    ion-textarea,
    ion-datetime,
    ion-select {
      border: 1px solid $color-grey-200;
      border-radius: 4px;
      transition: all 80ms linear;
      transition-property: border-color, background-color;

      &[readonly][disabled] {
        color: $color-grey-800;
        border-color: $color-grey-50;
        background-color: $color-grey-50;
      }
    }

    // Invalid state.
    &.ion-invalid.ion-touched:not(.input-has-focus):not(.item-input-has-focus) {
      ion-input,
      ion-textarea,
      ion-datetime,
      ion-select {
        border-color: $color-red-500;
      }
    }

    ion-datetime,
    ion-select {
      width: 100%;
    }

    ion-select {
      max-width: none;
    }

    // Adjust position of select arrow icon.
    ion-select .select-icon-inner {
      left: 0 !important;
    }

    &.input-has-focus {
      ion-input,
      ion-textarea,
      ion-datetime,
      ion-select {
        border-color: $color-primary;
        color: $color-primary;
      }
    }
  }
}

.form {
  &--item-no-padding {
    .item {
      padding-left: 0;
    }
  }
}

.list-md[no-lines] .item-input:last-child {
  border-width: 0 !important;
  box-shadow: none !important;
}

// Fix for ion-select icons being misaligned when inside an item with a stacked label
ion-select.in-item::part(icon) {
  transform: none;
}

.unset-height {
  height: unset;
  --min-height: unset;
}
