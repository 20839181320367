.alert-title {
  font-weight: $font-weight-medium !important;
}

alert-input-group.sc-ion-alert-md,
.alert-message.sc-ion-alert-md {
  color: var(--ion-text-color) !important;
}

.alert-message {
  p {
    margin-bottom: 0;
  }

  p:first-child {
    margin-top: 0;
  }
}

.alert-button:last-child {
  font-weight: $font-weight-medium !important;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.alert {
  margin-bottom: 1.5rem;
  padding: 0.75rem;
  color: $color-white;
  border-radius: 0.25rem;

  a {
    font-weight: $font-weight-medium;
    color: $color-white;
    text-decoration: none;
  }

  // Don't cut off long radio-button labels.
  &-ios,
  &-md {
    .alert-radio-label {
      white-space: normal;
    }
  }

  &__tip {
    display: flex;
    flex-direction: row;

    &--warn {
      background-color: $color-orange-50;
      color: $color-black;
    }

    &--hours {
      background-color: $color-grey-50;
      color: $color-grey-800;
      margin-top: 1rem;

      &-icon {
        color: $color-primary;
      }
    }
  }

  &--primary {
    background-color: $color-primary;
  }

  &--info {
    background-color: $color-grey-50;
    color: $color-grey-800;

    a {
      color: $color-brand-blue-500;
    }
  }

  &--success {
    background-color: $color-success;
  }

  &--warning {
    background-color: $color-warning;
  }

  &--danger {
    background-color: $color-danger;
  }

  &--no-margin {
    margin: 0;
  }

  &__cta-btn {
    margin-top: 0.5rem;

    .button {
      margin-left: 0;
    }
  }

  ion-col {
    padding: 0;
  }

  ion-col[size='auto'] hk-wc-icon {
    margin-right: 0.5rem;
  }

  p {
    margin: 0.5rem 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.alert-card {
  margin-bottom: 1.5rem;
  padding: 0.75rem;
  color: $color-black;
  border-radius: 0.25rem;

  &--info {
    background-color: $color-brand-blue-25;
    border: solid 1px $color-brand-blue-100;
  }

  &--warning {
    background-color: $color-orange-50;
    border: solid 1px $color-orange-200;
  }
  &--danger {
    background-color: $color-red-25;
    border: solid 1px $color-red-200;
  }

  ion-col {
    padding: 0;
  }

  ion-col[size='auto'] hk-wc-icon {
    margin-right: 0.5rem;
  }

  p {
    margin: 0.25rem 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
