$customColors: ('light-blue', 'orange', 'social-facebook', 'social-messenger', 'social-twitter', 'social-whatsapp');

@each $color in $customColors {
  .ion-color-#{$color} {
    --ion-color-base: var(--hk-color-#{$color}) !important;
    --ion-color-base-rgb: var(--hk-color-#{$color}-rgb) !important;
    --ion-color-contrast: var(--hk-color-#{$color}-contrast) !important;
    --ion-color-contrast-rgb: var(--hk-color-#{$color}-contrast-rgb) !important;
    --ion-color-shade: var(--hk-color-#{$color}-shade) !important;
    --ion-color-tint: var(--hk-color-#{$color}-tint) !important;
  }
}

/**
 * Named Color Variables
 */
$color-muted: $color-grey-500;

$color-danger: $color-red-500;
$color-success: $color-green-500;
$color-warning: $color-orange-500;
