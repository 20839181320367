ion-card {
  --ion-item-background: #fff;

  &.ios,
  &.md {
    --color: #{$color-black};
  }

  &.card-disabled {
    opacity: 0.75 !important;
  }
}

ion-card-header,
ion-card-title {
  font-weight: $font-weight-card-title;
  font-size: 1.15rem;
}

ion-card-subtitle {
  --color: var(--ion-color-step-500);
  font-weight: $font-weight-medium;
  text-transform: none;
  font-size: 1rem;
  letter-spacing: 0;
  margin: 10px 0 0;
}

// Reset font-weight of card-content elements on Android
.card-content-md {
  font-size: 1rem;

  p,
  ol,
  ul {
    font-weight: inherit;
    font-size: inherit;
  }
}

// Remove the top padding of `card-content` when next to `card-header`
ion-card-header + ion-card-content {
  padding-top: 0 !important;
}

// Use consistent font size for paragraphs and lists on iOS
.card-content-ios {
  font-size: 1rem;

  p,
  ol,
  ul {
    font-size: inherit;
  }

  ol,
  ul {
    padding-inline-start: 25px;
  }
}

ion-card.ion-margin-vertical {
  margin-left: 0;
  margin-right: 0;
  width: auto;

  .ion-padding &:first-child {
    margin-top: 0 !important;
  }
}

.card {
  // Remove additional horizontal padding when within an Ionic segment
  .segment-content & {
    width: auto;
  }

  .item {
    background-color: $color-white;
  }
}

.card-header--badged {
  ion-item {
    padding-left: 0;

    &.item > .item-inner {
      padding-right: 0;
    }

    [item-left],
    [item-right] {
      margin-right: 0;
    }

    [item-right] {
      margin-left: 0;
    }
  }
}

.card-header-md {
  font-weight: $font-weight-medium;
}

.card-md {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $font-weight-medium;
  }

  p {
    font-weight: inherit;
  }
}
