@import '@housekeep/design-tokens/dist/tokens';
@import './breakpoints';

.page-content {
  --background: #{$color-white};

  @media (min-width: $breakpoint-lg) {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 20px;
    --padding-bottom: 0;
  }
}

ion-footer {
  background: var(--ion-background-color);
  border-top: 1px solid var(--ion-item-border-color);
}
