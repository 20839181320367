// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/* Housekeep design tokens */
@import '@housekeep/design-tokens/dist/tokens';

/** Ionic CSS Variables **/
:root {
  /** fonts **/
  --ion-font-family: #{$font-family-base};
  --font-family-heading: #{$font-family-heading};

  /** base colors **/
  --ion-background-color: #fcfcfc;
  --ion-background-color-rgb: 252, 252, 252;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;

  --ion-color-white: #{$color-white};
  --ion-color-white-rgb: 255, 255, 255;

  --ion-overlay-background-color: var(--ion-background-color);
  --ion-toolbar-background: #{$color-brand-blue-500};
  --ion-toolbar-color: #ffffff;
  --ion-item-background: var(--ion-background-color);
  --ion-item-border-color: var(--ion-color-step-150);

  /** primary **/
  --ion-color-primary: #{$color-brand-blue-500};
  --ion-color-primary-rgb: #{red($color-brand-blue-500)}, #{green($color-brand-blue-500)},
    #{blue($color-brand-blue-500)};
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #{$color-brand-blue-600};
  --ion-color-primary-tint: #{$color-brand-blue-400};

  /** secondary **/
  --ion-color-secondary: #{$color-brand-bright-green-500};
  --ion-color-secondary-rgb: #{red($color-brand-bright-green-500)}, #{green($color-brand-bright-green-500)},
    #{blue($color-brand-bright-green-500)};
  --ion-color-secondary-contrast: var(--ion-text-color);
  --ion-color-secondary-contrast-rgb: var(--ion-text-color-rgb);
  --ion-color-secondary-shade: #{$color-brand-bright-green-600};
  --ion-color-secondary-tint: #{$color-brand-bright-green-400};

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #{$color-green-500};
  --ion-color-success-rgb: #{red($color-green-500)}, #{green($color-green-500)}, #{blue($color-green-500)};
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #{$color-green-600};
  --ion-color-success-tint: #{$color-green-400};
  --ion-color-success-light: #{$color-green-50};

  /** warning **/
  --ion-color-warning: #{$color-orange-500};
  --ion-color-warning-rgb: #{red($color-orange-500)}, #{green($color-orange-500)}, #{blue($color-orange-500)};
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #{$color-orange-600};
  --ion-color-warning-tint: #{$color-orange-400};
  --ion-color-warning-light: #{$color-orange-50};

  /** danger **/
  --ion-color-danger: #{$color-red-500};
  --ion-color-danger-rgb: #{red($color-red-500)}, #{green($color-red-500)}, #{blue($color-red-500)};
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #{$color-red-600};
  --ion-color-danger-tint: #{$color-red-400};
  --ion-color-danger-light: #{$color-red-50};

  /** dark **/
  --ion-color-dark: #{$color-grey-900};
  --ion-color-dark-rgb: #{red($color-grey-900)}, #{green($color-grey-900)}, #{blue($color-grey-900)};
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #{$color-black};
  --ion-color-dark-tint: #{$color-grey-800};

  /** medium **/
  --ion-color-medium: #{$color-grey-600};
  --ion-color-medium-rgb: #{red($color-grey-600)}, #{green($color-grey-600)}, #{blue($color-grey-600)};
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #{$color-grey-700};
  --ion-color-medium-tint: #{$color-grey-500};

  /** light **/
  --ion-color-light: #{$color-grey-100};
  --ion-color-light-rgb: #{red($color-grey-100)}, #{green($color-grey-100)}, #{blue($color-grey-100)};
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #{$color-grey-200};
  --ion-color-light-tint: #{$color-grey-50};

  /** Additional branded colours: **/

  /** light-blue **/
  --hk-color-light-blue: #e0eefb;
  --hk-color-light-blue-rgb: 224, 238, 251;
  --hk-color-light-blue-contrast: #2d8de1;
  --hk-color-light-blue-contrast-rgb: 45, 141, 225;
  --hk-color-light-blue-shade: #cae2f7;
  --hk-color-light-blue-tint: #f4f9fd;

  /* Social media colors */
  --hk-color-social-facebook: #3b5998;
  --hk-color-social-facebook-rgb: 59, 89, 152;
  --hk-color-social-facebook-contrast: #ffffff;
  --hk-color-social-facebook-contrast-rgb: 255, 255, 255;
  --hk-color-social-facebook-shade: #344e86;
  --hk-color-social-facebook-tint: #4f6aa2;

  --hk-color-social-messenger: linear-gradient(#00c6ff, #0078ff);
  --hk-color-social-messenger-rgb: 0, 198, 255;
  --hk-color-social-messenger-contrast: #ffffff;
  --hk-color-social-messenger-contrast-rgb: 255, 255, 255;
  --hk-color-social-messenger-shade: linear-gradient(#00aee0, #006ae0);
  --hk-color-social-messenger-tint: linear-gradient(#1accff, #1a86ff);

  --hk-color-social-twitter: #1da1f2;
  --hk-color-social-twitter-rgb: 29, 161, 242;
  --hk-color-social-twitter-contrast: #ffffff;
  --hk-color-social-twitter-contrast-rgb: 255, 255, 255;
  --hk-color-social-twitter-shade: #1a8ed5;
  --hk-color-social-twitter-tint: #34aaf3;

  --hk-color-social-whatsapp: #25d366;
  --hk-color-social-whatsapp-rgb: 37, 211, 102;
  --hk-color-social-whatsapp-contrast: #ffffff;
  --hk-color-social-whatsapp-contrast-rgb: 255, 255, 255;
  --hk-color-social-whatsapp-shade: #21ba5a;
  --hk-color-social-whatsapp-tint: #3bd775;

  /* Stepped greyscale colors */
  --ion-color-step-950: #{mix($color-black, $color-grey-900)};
  --ion-color-step-900: #{$color-grey-900};
  --ion-color-step-850: #{mix($color-grey-900, $color-grey-800)};
  --ion-color-step-800: #{$color-grey-800};
  --ion-color-step-750: #{mix($color-grey-800, $color-grey-700)};
  --ion-color-step-700: #{$color-grey-700};
  --ion-color-step-650: #{mix($color-grey-700, $color-grey-600)};
  --ion-color-step-600: #{$color-grey-600};
  --ion-color-step-550: #{mix($color-grey-600, $color-grey-500)};
  --ion-color-step-500: #{$color-grey-500};
  --ion-color-step-450: #{mix($color-grey-500, $color-grey-400)};
  --ion-color-step-400: #{$color-grey-400};
  --ion-color-step-350: #{mix($color-grey-400, $color-grey-300)};
  --ion-color-step-300: #{$color-grey-300};
  --ion-color-step-250: #{mix($color-grey-300, $color-grey-200)};
  --ion-color-step-200: #{$color-grey-200};
  --ion-color-step-150: #{mix($color-grey-200, $color-grey-100)};
  --ion-color-step-100: #{$color-grey-100};
  --ion-color-step-50: #{$color-grey-50};
  --ion-color-step-25: #{mix($color-grey-50, $color-white)};
}
