.background {
  &--muted {
    background-color: $color-grey-600 !important;
  }

  &--primary {
    background-color: $color-primary !important;
  }

  &--warning {
    background-color: $color-orange-500 !important;
  }

  &--danger {
    background-color: $color-red-500 !important;
  }

  &--success {
    background-color: $color-green-500 !important;
  }
}
