@import '@housekeep/design-tokens/dist/tokens';

ion-modal {
  @media only screen and (min-height: 768px) and (min-width: 768px) {
    --height: 700px !important;
    --max-height: 700px !important;
  }
}

.content-modal-alert,
.mood-sampling-alert,
.worker-rating-alert {
  color: $color-black;

  --max-width: 320px !important;

  &.ios {
    --border-radius: 13px;
    --background: #f8f8f8;

    &::part(content) {
      box-shadow: none;
    }
  }

  &.md {
    --border-radius: 2px;
    --background: #fafafa;

    &::part(content) {
      box-shadow: 0 16px 20px rgba(0, 0, 0, 0.4);
    }
  }

  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

.content-modal-alert {
  .ion-page {
    .inner-content {
      padding: 2rem;

      p.body {
        line-height: 1.4;
      }
    }
  }

  &.md {
    .ion-page {
      .inner-content {
        ion-button {
          --border-width: 1px;
          margin: 0;
        }
      }
    }
  }
}
