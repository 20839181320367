ion-badge {
  font-weight: $font-weight-medium;

  &.changed-badge {
    margin-left: 8px;
    vertical-align: top;

    &.ios {
      border-radius: 50rem;
    }
  }
}

.unread-indicator,
.badge-unread-indicator {
  color: $color-white;
  background-color: $color-warning;
  border-radius: 50%;
  position: absolute;
  z-index: 100;
}

.badge-unread-indicator {
  margin-left: 2px;
  width: 12px;
  height: 12px;

  hk-wc-icon[slot='start'] {
    margin-right: 0;
  }
}

.badge-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  padding: 2px;
  border-radius: 50%;
}

.icon-badge {
  &.ios {
    border-radius: 50rem;
  }
}
