@font-face {
  font-family: 'hk-icons';
  src: url('../assets/fonts/hk-icons.eot?iytp8o');
  src: url('../assets/fonts/hk-icons.eot?iytp8o#iefix') format('embedded-opentype'),
    url('../assets/fonts/hk-icons.ttf?iytp8o') format('truetype'),
    url('../assets/fonts/hk-icons.woff?iytp8o') format('woff'),
    url('../assets/fonts/hk-icons.svg?iytp8o#hk-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin makeHkIcon($name, $content) {
  .hk-#{$name}:before,
  .ion-ios-hk-#{$name}:before,
  .ion-ios-hk-#{$name}-outline:before,
  .ion-md-hk-#{$name}:before,
  .ion-md-hk-#{$name}-outline:before,
  .ion-wp-hk-#{$name}:before,
  .ion-wp-hk-#{$name}-outline:before {
    content: $content;
    speak: none;
    font-family: 'hk-icons' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// Housekeep icons
@include makeHkIcon(cleaning-products, '\e900');
@include makeHkIcon(bonus, '\e901');
// Social logos
@include makeHkIcon(logo-messenger, '\ea00');
@include makeHkIcon(logo-viber, '\ea01');
@include makeHkIcon(logo-vk, '\ea02');

hk-wc-icon[slot='start'] {
  margin-right: 0.4em;
}

hk-wc-icon[slot='end'] {
  margin-left: 0.4em;
}
