.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

ion-header ion-title {
  font-size: 1.15rem;
}

.app--dev-environment {
  ion-header > .toolbar-title-default:not([color]) {
    --background: repeating-linear-gradient(
      -45deg,
      #{$color-brand-blue-600},
      #{$color-brand-blue-600} 10px,
      #{$color-brand-blue-500} 10px,
      #{$color-brand-blue-500} 20px
    );
  }
}

.app--impersonate-mode {
  & ion-header > .toolbar-title-default:not([color]) {
    --background: repeating-linear-gradient(
      -45deg,
      #{$color-red-500},
      #{$color-red-500} 10px,
      #{$color-red-400} 10px,
      #{$color-red-400} 20px
    );
  }
}

ion-footer ion-toolbar {
  --min-height: initial;
  padding: 0.5rem !important;
  &::ng-deep toolbar-container {
    padding: 0;
  }
  > ion-button {
    margin: 0;
  }
}
