@import '@housekeep/design-tokens/dist/tokens';

ion-segment-button {
  color: $color-white;

  &.segment-button-checked::part(native) {
    color: $color-brand-blue-800;
  }

  &.ios {
    font-weight: 400;

    &::part(indicator-background) {
      background: $color-white;
    }
  }

  &.md {
    max-width: unset;

    &.segment-button-checked::part(native) {
      background: $color-white;
    }
  }
}

.segments-toolbar {
  ion-segment.ios {
    width: 100%;
    margin-inline: 15px;
  }
}
