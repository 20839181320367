$marginSizeMap: (
  auto: auto,
  '0': 0,
  '5': 5px,
  '8': 8px,
  '10': 10px,
  '15': 15px,
  '20': 20px,
  '25': 25px,
  '30': 30px
);

@each $name, $value in $marginSizeMap {
  // Margin
  .mar-#{$name} {
    margin: $value !important;
  }

  .mar-b-#{$name} {
    margin-bottom: $value !important;
  }

  .mar-t-#{$name} {
    margin-top: $value !important;
  }

  .mar-y-#{$name} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }

  .mar-r-#{$name} {
    margin-right: $value !important;
  }

  .mar-l-#{$name} {
    margin-left: $value !important;
  }

  .mar-x-#{$name} {
    margin-right: $value !important;
    margin-left: $value !important;
  }

  // Padding
  .pad-#{$name} {
    padding: $value !important;
  }

  .pad-b-#{$name} {
    padding-bottom: $value !important;
  }

  .pad-t-#{$name} {
    padding-top: $value !important;
  }

  .pad-y-#{$name} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }

  .pad-r-#{$name} {
    padding-right: $value !important;
  }

  .pad-l-#{$name} {
    padding-left: $value !important;
  }

  .pad-x-#{$name} {
    padding-right: $value !important;
    padding-left: $value !important;
  }
}
